<template>
  <div class="box100">
    <div class="whiteBg">
<!--      <template v-if="roleBtn[0].power">-->
<!--        <div class="el-icon-download settingExport"><span> 导出</span></div>-->
<!--      </template>-->
      <div class="flexAC" style="margin: 16px 0px;">
        <el-input v-model="keyWord"  placeholder="请输入编号/姓名" class="inputSearch"></el-input>
        <el-select v-model="keyStore" placeholder="全部门店" class="retSelect">
          <el-option v-for="item in storeList" :key="item.index" :label="item.shopname" :value="item.shopid"></el-option>
        </el-select>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          :data="kpiList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="排名"
            type="index">
          </el-table-column>
          <el-table-column
            label="编号"
            prop="sbecode">
          </el-table-column>
          <el-table-column
            label="姓名"
            prop="sname">
          </el-table-column>
          <el-table-column
            label="服务客户"
            prop="srcust">
          </el-table-column>
          <el-table-column
            label="客单价"
            prop="sruprice">
          </el-table-column>
          <el-table-column
            label="业绩"
            prop="srother1">
          </el-table-column>
          <el-table-column
            label="预约率"
            prop="srbrate">
          </el-table-column>
          <el-table-column
            label="好评率"
            prop="srfcom">
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { achieveQuery, storeDownQuery } from '@/api/store'

export default {
  name: 'storeKpi',
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      keyWord: '',
      keyStore: '',
      storeList: [],
      kpiList: []
    }
  },
  created () {
    this.init()
    this.getDown()
  },
  methods: {
    init () {
      const params = {
        shopid: this.keyStore,
        sname: this.keyWord,
        page_index: this.page,
        page_size: this.size
      }
      achieveQuery(params).then(res => {
        if (res.status === 1) {
          this.kpiList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    getDown () {
      storeDownQuery().then(res => {
        if (res.status === 1) {
          this.storeList = res.data.data
        }
      })
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.keyStore = ''
      this.keyWord = ''
      this.init()
    },
    pageChange (page) {
      this.page = page
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
